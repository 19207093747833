import Img from "gatsby-image"
import { Col, Container, Row } from "reactstrap"
import { useWindowSize } from "../../helper/hooks"

const AboutKlasse = ({ classNumber, classContent, image }) => {
  const size = useWindowSize()
  return (
    <Container
      tag="section"
      style={{ marginTop: "30px", marginBottom: "20px" }}
    >
      <Row>
        <Col xs="12">
          <h2>Markenanmeldung für {classContent}</h2>
        </Col>
        <Col xs="12" lg="9" xl="10">
          <p>
            Du träumst davon,{" "}
            {classNumber < 35 ? "dein Produkt" : "deine Dienstleistung"} unter
            deinem eigenen Markennamen{" "}
            {classNumber < 35 ? "zu vertreiben" : "Dienstleistung"}vertreiben?
            Wir helfen dir dabei, die richtige Nizzaklasse für dein Produkt im
            Bereich {classContent} zu bestimmen.
          </p>
        </Col>
        {/* {size && size.width && size.width > 892 ? ( */}
        <Col
          lg="3"
          xl="2"
          className="d-none d-lg-block"
          style={{ textAlign: "right" }}
        >
          <Img
            style={{ borderRadius: "50%" }}
            fixed={image}
            alt={`${classContent}`}
            title={`Nizza-Klasse ${classNumber}: ${classContent}`}
          ></Img>
        </Col>
        {/* ) : null} */}
      </Row>
    </Container>
  )
}
export default AboutKlasse

import { graphql } from "gatsby"
import React, { useState } from "react"
import AboutNizza from "../components/content/about-nizza"
import Hero from "../components/layout/hero"
import Layout from "../components/layout/layout"
import ResultSection from "../components/layout/result-section"
import { getSearchResults } from "../components/search/logic"
import SearchResults from "../components/search/search-results"
import SearchSection from "../components/search/search-section"
import Suche from "../components/search/suche"
import SEO from "../components/seo/seo"
import AboutKlasse from "../components/content/about-klasse"
import Divider from "../components/layout/divider"
import _ from "lodash"

const NizzaKlasseTemplate: React.FC<any> = ({
  pageContext,
  data,
  location,
}) => {
  const image = data.fileName.childImageSharp.fluid
  const imageFixed = data.fileName.childImageSharp.fixed

  const [searchResult, setSearchResult] = useState(pageContext.data)
  const [searchString, setSearchString] = useState("")

  const handleSearch = _.debounce((newSearchString: string) => {
    if (searchString !== newSearchString) {
      const newSearchResult = getSearchResults(
        pageContext.data,
        newSearchString,
        true
      )
      setSearchString(newSearchString)
      setSearchResult(newSearchResult)
    }
  }, 10);

  const seo = {
    title: `Nizza-Klasse ${pageContext.data[0].class} ⇒ Welche Produkte sind enthalten?`,
    keywords: pageContext.keywords,
    description: `Du möchtest eine Marke ${
      pageContext.classContent.length < 40
        ? `für ${pageContext.classContent} `
        : ""
    }anmelden? Nutze unsere Suchfunktion und finde die Nizza-Klasse deines Produkts oder deiner Dienstleistung heraus.`,
    location: location,
  }

  return (
    <Layout
      location={
        pageContext.data[0].class < 35 ? "/produkte" : "/dienstleistungen"
      }
    >
      <SEO {...seo} />

      <Hero
        fluidImage={image}
        title={`Nizza Klasse ${pageContext.id}`}
        hasSearchbar={true}
      >
        <div className="p">
          Diese Markenklasse beinhaltet u. a. folgende{" "}
          {Number(pageContext.id) <= 34 ? "Produkte" : "Dienstleistungen"}:{" "}
          <h2>{pageContext.classContent}</h2>.
        </div>
        <div className="p">
          Die <strong>Nizza-Klasse {pageContext.id}</strong> beinhaltet
          insgesamt <strong>{pageContext.data.length} Einträge</strong>.
          Durchsuche das Klassen-Register ganz einfach und bequem mit unserer
          Volltext-Suche. So findest du am schnellsten die{" "}
          <strong>
            zu{" "}
            {pageContext.id <= 34 ? "deinem Produkt" : "deiner Dienstleistung"}{" "}
            passende Markenklasse
          </strong>
          .
        </div>
      </Hero>

      <SearchSection showGlobalSearchLink={true}>
        <Suche
          searchString={searchString}
          handleSearch={handleSearch}
          // showAll={true}
          // showResults={false}
          placeholder={`Klasse ${pageContext.data[0].class} durchsuchen`}
        ></Suche>
      </SearchSection>

      <ResultSection title={`Suchergebnisse (${searchResult.length})`}>
        {SearchResults(searchResult, searchString)}
      </ResultSection>

      <AboutKlasse
        image={imageFixed}
        classContent={pageContext.classContent}
        classNumber={pageContext.className}
      ></AboutKlasse>
      <Divider fluid={false}></Divider>
      <AboutNizza></AboutNizza>
    </Layout>
  )
}
// "klassen/nizzaklasse-1.jpg"
export default NizzaKlasseTemplate

export const query = graphql`
  query CoverImageClass($image: String) {
    fileName: file(relativePath: { eq: $image }) {
      childImageSharp {
        fluid(maxWidth: 1920, maxHeight: 980) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 160, height: 160) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
